<template>
    <div>
        <v-menu
            bottom
            left
            content-class="project-action-content-menu"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
            </template>

            <v-list id="project-action-menu" class="v-application">
                <v-list-item class="rename-project" @click.native="editProjectName">
                    <v-list-item-content>
                        <p>
                            <v-btn icon color="primary">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                            <span>{{ $t('component_projectList_actionMenu_rename') }}</span>
                        </p>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item class="clone-project" @click.native="cloneProject">
                    <v-list-item-content>
                        <p>
                            <v-btn icon color="primary">
                                <v-icon>mdi-content-copy</v-icon>
                            </v-btn>
                            <span>{{ $t('component_projectList_actionMenu_clone') }}</span>
                        </p>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item class="delete-project" @click.native="deleteProject">
                    <v-list-item-content>
                        <p>
                            <v-btn icon color="error">
                                <v-icon>mdi-trash-can-outline</v-icon>
                            </v-btn>
                            <span>{{ $t('component_projectList_actionMenu_delete') }}</span>
                        </p>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-menu>

    </div>
</template>

<script>
export default {
    methods:{
        editProjectName(){
            this.params.context.componentParent.projectListAction("editProjectName", this.params.value)
        },
        cloneProject(){
            this.params.context.componentParent.projectListAction("cloneProject", this.params.value)
        },
        deleteProject(){
            this.params.context.componentParent.projectListAction("deleteProject", this.params.value)
        }
    }
}
</script>
