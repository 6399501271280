<template>

    <div style="line-height: 25px; overflow: hidden">
        <a
            class="mt-0 mb-1 custom-text-ellipsis"
            style="line-height: 15px;"
            :style="!params.value.title ? 'margin-top:6px!important' : ''"
            :href="params.value.redirectTo"
            target="_blank"
        >
            {{ params.value.url }}
        </a>

        <p
            class="my-0 custom-text-ellipsis"
            style="line-height: 15px; font-style: italic !important"
            v-if="params.value.title"
        >
            {{ params.value.title }}
        </p>
    </div>


</template>

<script>
export default {

}
</script>

<style lang="scss">

</style>
