<template>
    <div class="cell-render-text-ellipsis">
        {{ params.value }}
    </div>
</template>

<script>
export default {}
</script>

<style lang="scss">

.cell-render-text-ellipsis{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

}

</style>
