<template>
    <div v-if="params.value.componentName" class="cell-render-popin-table-link" @click="openPopin()">
        <div class="text-primary underline" style="cursor: pointer;">{{ params.value.displayedValue ? params.value.displayedValue : params.value.value }}</div>
    </div>
    <div v-else>
        {{ params.value.displayedValue ? params.value.displayedValue : params.value.value }}
    </div>
</template>

<script>
    export default {
        methods:{
            openPopin(){
                if(this.params.value.componentName){
                    this.showAgGridPopin(this.params.value)
                }
            }
        },
    }
</script>
