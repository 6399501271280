<template>
    <div class="cell-render-competition" v-if="!isNaN(params.value.value)">
        <span>{{$options.filters.formatNumber(params.value.value,1)}}%</span>
        <vs-progress :height="6" :percent="params.value.value" :color="getBarColor"></vs-progress>
    </div>
    <div class="cell-render-competition" v-else>
        <span>{{params.value.value}}</span>
    </div>
</template>

<script>
export default {
    computed:{
        getBarColor(){
            let color = this.getThemeColorByName('progressBar1')
            if(this.params.value.value > 25){ color = this.getThemeColorByName('progressBar2')}
            if(this.params.value.value > 50){ color = this.getThemeColorByName('progressBar3')}
            if(this.params.value.value > 75){ color = this.getThemeColorByName('progressBar4')}

            if(this.params.value.progression){
                color = this.getThemeColorByName('progressBar4')
                if(this.params.value.value > 25){ color = this.getThemeColorByName('progressBar3')}
                if(this.params.value.value > 50){ color = this.getThemeColorByName('progressBar2')}
                if(this.params.value.value > 75){ color = this.getThemeColorByName('progressBar1')}
            }
            return color
        }
    }
}
</script>

<style lang="scss">
.cell-render-competition{
    width: 100%;
    >span{
        display: block;
        line-height: 12px;
        text-align: left;
        padding-top: 10px;
        margin-bottom: -10px
    }
}
</style>
