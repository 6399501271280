<template>
    <div class="cell-render-tags">
        <vs-chip v-for="tag in params.value.tags" :class="getChipClass" :key="tag.id" :color="getChipColor" closable @click="removeSegment(tag)">
            {{ tag.name }}
        </vs-chip>
    </div>
</template>

<script>
export default {
    computed:{
      getChipColor(){
          if(this.params.value.color){
              return this.params.value.color
          }
          return ''
      },
      getChipClass(){
          if(this.params.value.chipsClass){
              return this.params.value.chipsClass
          }
          return ''
      }
    },
    methods:{
        removeSegment(tag){
            this.params.context.componentParent.onDeleteTag({data:this.params.data,tag:tag})
        }
    },
}
</script>

<style lang="scss">

.cell-render-tags{
    .con-vs-chip{
        max-height: 32px;
    }
}

</style>
