<template>
    <div class="cell-render-with-label v-application" style="width: 100%">
        <span class="d-inline-block text-right mr-4" style="width: 100px">
            {{ params.value.value }}
        </span>
        <v-progress-linear
            class="d-inline-block"
            background-color="none"
            rounded
            height="8"
            :color="params.value.color"
            style="width: calc(100% - 110px)"
            :value="params.value.percent"
        />
    </div>
</template>

<script>
export default {}
</script>
