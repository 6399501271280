
<template>
    <div class="table-container" ref="agGridTableContainer">
        <ag-grid-vue
            class="ag-theme-material ag-grid-table"
            :style="agGridStyle"


            :columnDefs="tableData.columnDefs"
            :rowData="tableData.rowData"

            :gridOptions="gridOptions"
            :defaultColDef="defaultColDef"
            :floatingFilter="showFloatingFilter"
            :rowMultiSelectWithClick="true"
            rowSelection="multiple"
            @selection-changed="onRowSelected"
            @delete-tag="onDeleteTag"
            @pagination-changed="onPaginationChange"
            @body-scroll="onPaginationChange"
            @filter-changed="onFilterChanged"
            :context="context"
            :pagination="showPagination"
            :paginationPageSize="paginationPageSize"
            :suppressPaginationPanel="true"
            :headerHeight="headerHeight"
            :enableBrowserTooltips="true"

            :animateRows="true"

            :localeText="localeText"

        >
        </ag-grid-vue>
        <br v-if="showPagination">
        <vs-pagination
            v-if="showPagination"
            :total="totalPageCount"
            :max="7"
            v-model="currentPage" />

    </div>


</template>

<script>
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import { AgGridVue } from 'ag-grid-vue'

import CellRendererRankProgression from "@/components/yooda-components/ag-grid-table/cell-renderer/CellRendererRankProgression";
import CellRendererProgression from "@/components/yooda-components/ag-grid-table/cell-renderer/CellRendererProgression";
import CellRendererLink from "@/components/yooda-components/ag-grid-table/cell-renderer/CellRendererLink";
import CellRendererLinkIcon from "@/components/yooda-components/ag-grid-table/cell-renderer/CellRendererLinkIcon";
import CellRendererTextColor from "@/components/yooda-components/ag-grid-table/cell-renderer/CellRendererTextColor";
import CellRendererCurrency from "@/components/yooda-components/ag-grid-table/cell-renderer/CellRendererCurrency";
import CellRendererFormatedNumber from "@/components/yooda-components/ag-grid-table/cell-renderer/CellRendererFormatedNumber";
import CellRendererProjectListAction from "@/components/yooda-components/ag-grid-table/cell-renderer/CellRendererProjectListAction";
import CellRendererPopinTableLink from "@/components/yooda-components/ag-grid-table/cell-renderer/CellRendererPopinTableLink";
import CellRendererPopinChartLink from "@/components/yooda-components/ag-grid-table/cell-renderer/CellRendererPopinChartLink";
import CellRendererPercent from "@/components/yooda-components/ag-grid-table/cell-renderer/CellRendererPercent";
import CellRendererAdwordAd from "@/components/yooda-components/ag-grid-table/cell-renderer/CellRendererAdwordAd";
import CellRendererShoppingAd from "@/components/yooda-components/ag-grid-table/cell-renderer/CellRendererShoppingAd";
import CellRendererCompetition from "@/components/yooda-components/ag-grid-table/cell-renderer/CellRendererCompetition";
import CellRendererWithLabel from "@/components/yooda-components/ag-grid-table/cell-renderer/CellRendererWithLabel";
import CellRendererWithIcon from "@/components/yooda-components/ag-grid-table/cell-renderer/CellRendererWithIcon";
import CellRendererTags from "@/components/yooda-components/ag-grid-table/cell-renderer/CellRendererTags";
import CellRendererCompetitorDisplay from "@/components/yooda-components/ag-grid-table/cell-renderer/CellRendererCompetitorDisplay";
import CellRendererTextWithIcon from "@/components/yooda-components/ag-grid-table/cell-renderer/CellRendererTextWithIcon";
import CellRendererPotentialTraffic from "@/components/yooda-components/ag-grid-table/cell-renderer/CellRendererPotentialTraffic.vue";
import CellRendererLinkingProgressBar from "@/components/yooda-components/ag-grid-table/cell-renderer/CellRendererLinkingProgressBar.vue";
import CellRendererLinkingThematic from "@/components/yooda-components/ag-grid-table/cell-renderer/CellRendererLinkingThematic.vue";
import CellRendererLinkingPopularityDetail from "@/components/yooda-components/ag-grid-table/cell-renderer/CellRendererLinkingPopularityDetail.vue";
import CellRendererLinkingInfoChips from "@/components/yooda-components/ag-grid-table/cell-renderer/CellRendererLinkingInfoChips.vue";
import CellRendererLinkingBacklinkDetail from "@/components/yooda-components/ag-grid-table/cell-renderer/CellRendererLinkingBacklinkDetail.vue";
import CellRendererTextEllipsis from "@/components/yooda-components/ag-grid-table/cell-renderer/CellRendererTextEllipsis.vue";


export default {
    props: {
        isCardContainerFullscreen:{
            type: Boolean,
            default: false
        },
        tableData: {},
        withTableMenu: {
            type: Boolean,
            default: true
        },
        withFullscreen: {
            type: Boolean,
            default: true
        },
        showPagination: {
            type: Boolean,
            default: true
        },
        showFloatingFilter: {
            type: Boolean,
            default: true
        },
        headerHeight:{
            type: Number,
            default: 50
        },
        paginationSize:{
            type: Number,
            default: 12
        },
        agGridStyle:{
            type: String,
            default: ''
        },
        agGridOptions:{
            type: Object,
            default(){
                return {
                    rowHeight: 56
                }
            }

        },

        sizeColumnsToFit: {
            type: Boolean,
            default: true
        },
    },
    data(){
        return {
            paginationPageSize:(this.isCardContainerFullscreen ? 50 : this.paginationSize),
            hasDensitySelector:false,
            isLoaded: false,
            gridOptions: {
                sortingOrder: ['asc', 'desc'],
                enableCellTextSelection:true,
                api: null,
                ...this.agGridOptions
            },

            context: null,
            columnDefs: null,
            totalPageCount: 0,
            defaultColDef: {
                sortable: true,
                resizable: true,
                suppressMenu: false
            },
            rowData: null,
            localeText: {
                selectAll: this.$t('common_agGridTable_selectAll'),
                selectAllSearchResults: this.$t('common_agGridTable_selectAllSearchResults'),
                searchOoo: this.$t('common_agGridTable_searchOoo'),
                blanks: this.$t('common_agGridTable_blanks'),
                noMatches: this.$t('common_agGridTable_noMatches'),
                filterOoo: this.$t('common_agGridTable_filterOoo'),
                equals: this.$t('common_agGridTable_equals'),
                notEqual: this.$t('common_agGridTable_notEqual'),
                lessThan: this.$t('common_agGridTable_lessThan'),
                greaterThan: this.$t('common_agGridTable_greaterThan'),
                lessThanOrEqual: this.$t('common_agGridTable_lessThanOrEqual'),
                greaterThanOrEqual: this.$t('common_agGridTable_greaterThanOrEqual'),
                inRange: this.$t('common_agGridTable_inRange'),
                inRangeStart: this.$t('common_agGridTable_inRangeStart'),
                inRangeEnd: this.$t('common_agGridTable_inRangeEnd'),
                contains: this.$t('common_agGridTable_contains'),
                notContains: this.$t('common_agGridTable_notContains'),
                startsWith: this.$t('common_agGridTable_startsWith'),
                endsWith: this.$t('common_agGridTable_endsWith'),
                dateFormatOoo: this.$t('common_agGridTable_dateFormatOoo'),
                andCondition: this.$t('common_agGridTable_andCondition'),
                orCondition: this.$t('common_agGridTable_orCondition'),
                initComponentData: this.$t('common_agGridTable_initComponentData'),
                resetFilter: this.$t('common_agGridTable_resetFilter'),
                clearFilter: this.$t('common_agGridTable_clearFilter'),
                cancelFilter: this.$t('common_agGridTable_cancelFilter'),
                columns: this.$t('common_agGridTable_columns'),
                filters: this.$t('common_agGridTable_filters'),
                noRowsToShow: this.$t('common_agGridTable_noRowsToShow'),
            }
        }
    },
    computed: {
        currentPage: {
            get () {
                if (this.gridOptions.api) {
                    return this.gridOptions.api.paginationGetCurrentPage() + 1
                }
                else {
                    return 1
                }
            },
            set (val) {
                if (this.gridOptions.api){
                    this.gridOptions.api.paginationGoToPage(val - 1)
                }
            }
        },
    },
    watch:{
        isCardContainerFullscreen(newValue){
            if(newValue){
                this.gridOptions.api.paginationSetPageSize(50)
            }
            else{
                this.gridOptions.api.paginationSetPageSize(this.paginationPageSize)
            }
            this.initApi()
        },
        getTableDensity(){
            this.initApi()
        }
    },
    methods:{
        projectListAction(eventName, value){
            this.$emit(eventName,value)
        },
        onRowSelected(){
            this.$emit('selected-row',this.gridOptions.api.getSelectedRows())
        },
        onDeleteTag(value){
            this.$emit('delete-tag',value)
        },
        onPaginationChange(){
            this.$emit('pagination-change',this.gridOptions.api.getRenderedNodes())
        },
        onFilterChanged(){
            this.initApi()
        },
        formatCsvExport(cell){
            let cellValue = ''


            if(cell.value !== undefined && cell.value !== null){
                if(cell.column.colId === 'thematic'){
                    cellValue = ''
                    if(cell.value){

                        if(cell.value.categories && cell.value.categories.length){
                            cellValue += `${cell.value.categories.join('/')}:${cell.value.value}`
                        }
                        if(cell.value.otherThematic && cell.value.otherThematic.length){
                            cell.value.otherThematic.forEach((thematic)=>{
                                if(thematic.categories.length){
                                    cellValue += ` - ${thematic.categories.join('/')}:${thematic.value}`
                                }
                            })
                        }
                    }
                }else{
                    if(typeof cell.value === 'string' || typeof cell.value === 'number'){
                        cellValue = cell.value
                    }
                    else{
                        if(cell.value.value !== undefined && cell.value.value !== null){
                            cellValue = cell.value.value
                        }
                        else{
                            if(cell.value.isAdwordsRenderCell){
                                cellValue = `${(cell.value.source ? cell.value.source : '')} | ${(cell.value.title ? cell.value.title : '')} | ${(cell.value.description ? cell.value.description : '')} | ${(cell.value.url ? cell.value.url : '')}`
                            }
                            else{
                                if(cell.value.isShoppingRenderCell){
                                    cellValue = `${(cell.value.title ? cell.value.title : '')} | ${(cell.value.price ? cell.value.price : '')} | ${(cell.value.source ? cell.value.source : '')} | ${(cell.value.url ? cell.value.url : '')} | ${(cell.value.merchantCenter ? cell.value.merchantCenter : '')}`
                                }
                                else{
                                    if(cell.value.chartData !== undefined && cell.value.chartData !== null){
                                        cellValue = JSON.stringify(cell.value.chartData).replaceAll(',','|')
                                    }else{
                                        if(cell.value.rank !== undefined && cell.value.rank !== null){
                                            cellValue = cell.value.rank
                                        }
                                        else{
                                            if(cell.value.rankEvolution !== undefined && cell.value.rankEvolution !== null){
                                                cellValue = (cell.value.rankEvolution === 0 ? '=' : cell.value.rankEvolution)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            if(typeof cellValue === 'string'){
                cellValue = cellValue.replaceAll(',','')
                if(cellValue.trim() === '-'){cellValue = ''}
            }

            return cellValue
        },
        formatCsvHeader(header){
            return header.column.colDef.headerName.replaceAll(',',';')
        },
        exportCsvTable(){
            this.gridOptions.api.exportDataAsCsv({
                processCellCallback:this.formatCsvExport,
                processHeaderCallback:this.formatCsvHeader,
                suppressQuotes:true,
                columnSeparator:';'
            })
        },
        initApi(){
            if (!this.isLoaded) {
                if(this.tableData.rowData && this.tableData.rowData.length>0){
                    this.isLoaded = true
                    this.gridOptions.api.paginationGoToPage(0)
                }
            }



            let newPaginationSize = 0
            let newRowHeight = 0
            switch(this.getTableDensity){
                case 'relaxed':
                    if(this.agGridOptions.rowHeight === 56){
                        newPaginationSize = 12
                    }
                    else{
                        newPaginationSize = 8
                    }
                    newRowHeight = this.agGridOptions.rowHeight
                    break;
                case 'regular':

                    if(this.agGridOptions.rowHeight === 56){
                        newRowHeight = 48
                        newPaginationSize = 14
                    } else{
                        newRowHeight = 72
                        newPaginationSize = 9
                    }
                    break;
                case 'condensed':
                    if(this.agGridOptions.rowHeight === 56){
                        newRowHeight = 40
                        newPaginationSize = 16
                    } else{
                        newRowHeight = 60
                        newPaginationSize = 11
                    }
                    break;
            }


            if(this.hasDensitySelector){
                this.gridOptions.rowHeight = newRowHeight
                this.gridOptions.api.paginationSetPageSize(newPaginationSize)
                this.gridOptions.api.resetRowHeights()
            }

            this.totalPageCount = this.gridOptions.api.paginationGetTotalPages()
            if(this.sizeColumnsToFit){
                this.gridOptions.api.sizeColumnsToFit()
            }

        },
    },


    updated() {
        this.initApi()
    },
    beforeMount() {

        this.context = { componentParent: this }
    },
    mounted() {
        if(this.$refs.agGridTableContainer.parentNode.classList.contains('tableDensitySelector')){
            this.hasDensitySelector = true
        }

        this.initApi()

    },
    components: {
        AgGridVue,
        CellRendererRankProgression,
        CellRendererProgression,
        CellRendererLink,
        CellRendererLinkIcon,
        CellRendererTextColor,
        CellRendererCurrency,
        CellRendererPopinTableLink,
        CellRendererPopinChartLink,
        CellRendererPercent,
        CellRendererAdwordAd,
        CellRendererShoppingAd,
        CellRendererCompetition,
        CellRendererFormatedNumber,
        CellRendererProjectListAction,
        CellRendererWithLabel,
        CellRendererWithIcon,
        CellRendererTags,
        CellRendererCompetitorDisplay,
        CellRendererTextWithIcon,
        CellRendererPotentialTraffic,
        CellRendererLinkingProgressBar,
        CellRendererLinkingThematic,
        CellRendererLinkingPopularityDetail,
        CellRendererLinkingInfoChips,
        CellRendererLinkingBacklinkDetail,
        CellRendererTextEllipsis,

    }
}
</script>

<style lang="scss">




</style>

