<template>
    <div class="cell-render-link">
        <v-icon class="mr-4" v-if="params.value.withIcon">mdi-web</v-icon>
        <a :href="params.value.href" :target="target">{{ params.value.value }}</a>
    </div>
</template>

<script>
export default {
    computed:{
        target(){
            if(this.params.value.targetBlank){
                return '_blank'
            }
            else{
                return '_self'
            }
        }
    },
}
</script>

