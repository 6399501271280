<template>
    <div class="cell-render-adword-ad">


        <p class="cell-render-adword-ad-url"><span>{{ $t('common_label_ad') }} - </span>{{ params.value.source }}</p>
        <p class="cell-render-adword-ad-title">{{ params.value.title }}</p>
        <p class="cell-render-adword-ad-rate">{{ params.value.rate }}</p>
        <p class="cell-render-adword-ad-description">{{ params.value.description }}</p>
        <p class="cell-render-adword-ad-link">{{ params.value.url }}</p>
<!--        <p class="cell-render-adword-ad-link">{{ params.value.link }}</p>-->
    </div>
</template>

<script>
export default {}
</script>

<style lang="scss">

.cell-render-adword-ad{
    >p{
        margin: 0;
        padding: 0;
        line-height: normal;
    }
    .cell-render-adword-ad-url{
        color: #202124;
        margin: 0 0 10px 0;
        font-size: 13px;
        span{
            font-weight: 600;
            margin: 0 0 0 0;
        }
    }
    .cell-render-adword-ad-title{
        margin: 4px 0 0 0;
        color: #1a0dab;
        font-weight: 500;
        font-size: 18px;
    }
    .cell-render-adword-ad-rate{
        margin: 4px 0 0 0;
        color: $googleResultTypeAdwords;
        font-size: 13px;
    }
    .cell-render-adword-ad-description{
        margin: 6px 0 0 0;
        font-size: 13px;
        white-space: pre-wrap;
        color: #4d5156;
    }

    .cell-render-adword-ad-link{
        margin: 6px 0 0 0;
        color: $googleResultTypeMap;
        font-weight: 600;
        font-size: 12px;
    }
}

</style>
