<template>

    <v-tooltip top open-delay="200">
        <template v-slot:activator="{ on, attrs }">
            <div
                v-bind="attrs" v-on="on"
                class="cellrenderer-linking-popularity-detail"
                :style="`color: ${params.value.color}; border-color:${params.value.color}; background-color:${params.value.color}10`"
            >
                {{ params.value.value }}
                <v-icon small :color="params.value.color" class="ml-1">mdi-eye-outline</v-icon>
            </div>
        </template>
        <div>
            <p v-html="$t('component_cellrenderer_popularityDetail_authority').replace('[*POPULARITY*]', params.value.referralPagePopularity).replace('[*LINK-COUNT*]', params.value.countLink)"/>
        </div>
    </v-tooltip>




</template>

<script>
export default {

}
</script>

<style lang="scss">

</style>
