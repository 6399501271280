<template>
    <div class="cell-render-competitor-display">
        <v-icon
            small
            :color="iconColor"
        >
            mdi-{{ iconName }}
        </v-icon>
        <span class="cell-render-competitor-display-text">{{ params.value }}</span>
    </div>
</template>

<script>
export default {
    name: "CellRendererCompetitorDisplay",
    data() {
        return  {
            iconName: '',
            iconColor: ''
        }
    },
    beforeMount() {
        if (this.params.data) {
            if (this.params.data.competitorType === 'watched') {
                this.iconName = 'star'
                this.iconColor = 'blue'
            } else if (this.params.data.competitorType === 'project') {
                this.iconName = 'star'
                this.iconColor = 'yellow darken-2'
            } else if (this.params.data.competitorType === 'other') {
                this.iconName = 'star-outline'
                this.iconColor = 'grey'
            }
        }
    }

}
</script>

<style lang="scss">
.cell-render-competitor-display{
    display: flex;
    align-items: center;
    .cell-render-competitor-display-text{
        display: inline-block;
        margin: 0 0 0 12px;
        strong{
            font-weight: normal;
        }
    }
}
</style>
