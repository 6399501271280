<template>

    <div v-if="params.value.categories && params.value.categories.length >0">
        <v-tooltip
            top
            open-delay="200"
            color="primary"
            content-class="cellrenderer-linking-thematic-tooltip"
            :disabled="params.value.disabledHover"
        >

            <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" class="linking-thematic-display" :class="params.value.otherThematic && params.value.otherThematic.length>0 ? 'with-other' : ''">
                    <div>
                        <mark :style="`background-color:${params.value.color}`"/>
                        <span v-for="(category, index) in params.value.categories" :key="index">
                            {{ category }}
                            <em>/</em>
                        </span>
                    </div>
                    <div class="ml-1 mr-4" v-if="params.value.otherThematic && params.value.otherThematic.length>0">+{{ params.value.otherThematic.length }}</div>
                </div>
            </template>


            <div style="position:relative;" >
                <strong style="display: block" class="my-2">{{ $t('component_linkingCompetitorsLinking_table_cellrenderer_tooltip_title1') }}</strong>
                <div class="linking-thematic-display mb-4">
                    <sub :style="`background-color:${params.value.color}`">{{params.value.value}}</sub>
                    <div>
                        <span v-for="(category, index) in params.value.categories" :key="index">
                            {{ category }}
                            <em>/</em>
                        </span>
                    </div>
                </div>

                <div v-if="params.value.otherThematic && params.value.otherThematic.length>0">
                    <strong style="display: block" class="my-2">{{ $t('component_linkingCompetitorsLinking_table_cellrenderer_tooltip_title2') }}</strong>
                    <div class="linking-thematic-display mb-4" v-for="(thematic,thematicIndex) in params.value.otherThematic" :key="thematicIndex">
                        <sub :style="`display: inline-block; background-color:${thematic.color}`">{{thematic.value}}</sub>
                        <div>
                        <span v-for="(category, index) in thematic.categories" :key="index">
                            {{ category }}
                            <em>/</em>
                        </span>
                        </div>
                    </div>
                </div>

            </div>
        </v-tooltip>
    </div>


</template>

<script>
export default {

}
</script>

<style lang="scss">

</style>
