<template>
    <div class="cell-render-progression" v-if="params.value">

        <div class="cell-render-rank-progression-evo-sign" >
            <div v-if="!iconName">-</div>
            <strong :style="{fontSize:'12px',color:getThemeColorByName(evoColor)}" v-else-if="displayNewOut">{{iconName}}</strong>
            <div v-else>
                <yooda-icon :name="iconName" :color="evoColor" :size="iconSize"></yooda-icon>
                <strong v-if="params.value.rankEvolution" :style="{fontSize:'12px',color:getThemeColorByName(evoColor)}">{{ params.value.rankEvolution }}</strong>
            </div>

        </div>
    </div>
    <div v-else>
        <span>-</span>
    </div>

</template>

<script>
import YoodaIcon from "@/components/yooda-components/YoodaIcon";
export default {
    data(){
        return {
            iconName: null,
            iconSize:10,
            evoColor: '',

        }
    },
    components:{
        YoodaIcon
    },
    computed:{
        displayNewOut(){
            return (this.iconName === 'out' || this.iconName === 'new')
        }
    },
    beforeMount() {
        if (this.params.value) {

            if (this.params.value.rankEvolution === 0) {
                this.iconName = 'equal'
            } else {
                if (this.params.value.rankEvolution === 'New') {
                    this.iconName = 'new'
                    this.evoColor = 'positiveVariation'
                } else if (this.params.value.rankEvolution === 'Out') {
                    this.iconName = 'out'
                    this.evoColor = 'negativeVariation'
                } else {
                    if (this.params.value.rankEvolution > 0) {
                        this.iconName = 'caret-up'
                        this.evoColor = 'positiveVariation'
                        this.iconSize = 12
                    } else if(this.params.value.rankEvolution < 0) {
                        this.iconName = 'caret-down'
                        this.evoColor = 'negativeVariation'
                        this.iconSize = 12
                    }
                }
            }
        }
    }

}
</script>

<style lang="scss">
.cell-render-progression{
    display: inline-block;
    .cell-render-rank-progression-evo-sign{
        display: inline-block;
        margin: 0 0 0 5px;
        strong{
            font-weight: normal;
        }
    }
}
</style>
