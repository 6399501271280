<template>
    <div class="cell-render-currency" v-if="!isNaN(params.value)">
        {{ params.value|formatNumber }} €
    </div>
    <div class="cell-render-currency" v-else>
        {{ params.value }}
    </div>
</template>

<script>
export default {}
</script>

