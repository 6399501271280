<template>
    <div class="v-application">
        <span v-if="params.value.label" class="mr-3">{{ params.value.label }}</span>
        <a :href="params.value.href" :target="target"><v-icon :size="iconSize">{{iconName}}</v-icon></a>
    </div>
</template>

<script>
export default {
    computed:{
        target(){
            if(this.params.value.targetBlank){
                return '_blank'
            }
            else{
                return '_self'
            }
        },
        iconName(){
            if(this.params.value.iconName){
                return this.params.value.iconName
            }
            else{
                return 'mdi-magnify'
            }
        },
        iconSize(){
            if(this.params.value.iconSize){
                return this.params.value.iconSize
            }
            else{
                return ''
            }
        }
    }
}
</script>

