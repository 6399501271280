<template>
    <div class="cell-render-with-icon">
        <span>{{ params.value.value }}</span> <yooda-icon :name="params.value.iconName" :color="params.value.iconColor" :size="params.value.iconSize"></yooda-icon>
    </div>
</template>

<script>
export default {}
</script>

<style lang="scss">

.cell-render-with-icon{
    span{
        display: inline-block;
        vertical-align: middle;
        &.yooda-icon{
            margin-left: 5px;
        }
    }
}

</style>
