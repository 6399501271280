<template>
    <div class="echart-container">
        <e-charts
            :options="options"
            :ref="chartType"
            :style="chartStyle"
            autoresize
            theme="yooda-one"
            @mouseover="onMouseover"
        />
    </div>

</template>

<script>

import ECharts from 'vue-echarts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/title'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/legendScroll'
import 'echarts/lib/component/toolbox'
import 'echarts/lib/component/markArea'
import 'echarts/lib/chart/line'
import 'echarts/lib/chart/bar'
import 'echarts/lib/chart/radar'
import 'echarts/lib/chart/pie'
import yoodaOneEchartTheme from "@/components/echartTheme";

ECharts.registerTheme('yooda-one', yoodaOneEchartTheme)


    export default {
        name: 'YoodaChart',
        props: {
            options: Object,
            chartType: String,
            chartStyle: String,
            onMouseover: {
                type:Function,
                default:(function(){})
            }
        },
       components:{
           ECharts
       },

    }
</script>

<style lang="scss" >

</style>
