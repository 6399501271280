<template>
    <div style="width: 100%">
        <v-row>
            <v-col :cols="12">
                <yooda-chart
                    v-if="getChartData"
                    :options="getChartData"
                    chart-type="line"
                    chart-style="width:100%!important;height:75px; margin:2px 0 0 0"
                />
            </v-col>

        </v-row>


    </div>

</template>

<script>

import YoodaChart from "@/components/yooda-components/YoodaChart";
import ECharts from "vue-echarts";

export default {
    data() {
        return {}
    },
    components: {
        YoodaChart,
    },
    methods:{
        tooltipFormatter(tooltipParams){
            if(this.params.value.hasOwnProperty('params') && this.params.value.params.hasOwnProperty('formatter') && this.params.value.params.formatter === 'position'){
                if(tooltipParams[0].data && tooltipParams[0].value){
                    return `${this.$options.filters.displayDateLocale(tooltipParams[0].data.date)}<br/>${this.$t('common_rank_position')} : ${this.getRankPositionLabel(tooltipParams[0].value)}`
                }
                return `${this.$options.filters.displayDateLocale(tooltipParams[0].data.date)}<br/>${this.$t('common_rank_notPositioned')}`
            }
            else{
                if(tooltipParams[0].data){
                    return `${this.$options.filters.displayDateLocale(tooltipParams[0].data.date)}<br/>${this.$t('common_rank_visibility')} : ${new Intl.NumberFormat('fr-FR',{maximumFractionDigits:0}).format(tooltipParams[0].value)}`
                }
                return ''
            }
        },
    },
    computed:{
        getChartData(){
            let data = this.params.value.chartData
            let inverse = this.params.value.hasOwnProperty('params')
                && this.params.value.params.hasOwnProperty('yAxisInverted')
                && this.params.value.params.yAxisInverted

            if (!data){data = []}
            let maxValue = 99999999999
            let minValue = 0
            // if(data.length === undefined){
            //     data = Object.entries(data).map(data=>{if(data[1]) { return data[1] }})
            // }

            if(data.length === 0){return null}
            data.forEach(value => {

                if(value.rank){
                    if(value.rank < maxValue){ maxValue = value.rank }
                    if(value.rank > minValue){ minValue = value.rank }
                }
                else{
                    if(value.cumulatedVisibility < maxValue){ maxValue = value.cumulatedVisibility }
                    if(value.cumulatedVisibility > minValue){ minValue = value.cumulatedVisibility }
                }

            })
            maxValue -= (maxValue*10)/100


            return {
                grid: { left: 10, top: 10, right: 10, bottom: 10},
                tooltip: {
                    trigger: 'axis',
                    formatter : this.tooltipFormatter,

                    position: function (point, params, dom, rect, size) {
                        return [(point[0] > size.viewSize[0]*0.6 ? size.viewSize[0]*0.6 : point[0]), '5%'];
                        // return [(point[0] > size.viewSize[0]*0.9 ? size.viewSize[0]*0.9 : point[0]), point[1]];
                    }
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    show: false,
                    data:data.map((value, index)=> {return index})
                },
                yAxis: {
                    type: 'value',
                    show: false,
                    min: maxValue,
                    max:minValue+10,
                    inverse: inverse
                },
                series: [ {
                    type: 'line',
                    symbolSize: 4,
                    showSymbol:false,
                    lineStyle:{ width:1 },
                    data: data.map((value)=>{
                        return {value: (value.rank ? value.rank : value.cumulatedVisibility), date:value.date}
                    }),
                    color:this.getThemeColorByName('primary'),
                    smooth:false,
                    areaStyle: {
                        origin:(inverse ? 'end' : 'start'),
                        color: new ECharts.graphic.LinearGradient(0, 0.1, 0, 0.9, [{
                            offset: 0,
                            color: this.getThemeColorByName('primary',0.2)
                        }, {
                            offset: 1,
                            color: 'rgb(255,255,255)'
                        }])
                    },
                } ]
            }
        }
    },
}
</script>

