<template>
    <div class="cell-render-shopping-ad vx-row">
        <div class="vx-col w-full md:w-1/2 lg:w-1/2 xl:w-1/2" style="max-width: 140px">
            <div class="cell-render-shopping-ad-img">
                <img v-if="params.value.image" :src="params.value.image" :alt="params.value.title">
            </div>
        </div>
        <div class="vx-col w-full md:w-1/2 lg:w-1/2 xl:w-1/2">
            <div>
                <p class="cell-render-shopping-ad-title">{{ params.value.title }}</p>
                <p class="cell-render-shopping-ad-price">{{ params.value.price }}</p>
                <p class="cell-render-shopping-ad-rate">{{ params.value.score }}</p>
                <p class="cell-render-shopping-ad-website">{{ params.value.source }}</p>
                <p class="cell-render-shopping-ad-url">{{ params.value.url }}</p>
                <p class="cell-render-shopping-ad-link">{{ params.value.merchantCenter }}</p>
            </div>
        </div>
    </div>

</template>

<script>
export default {}
</script>

<style lang="scss">

.cell-render-shopping-ad{
    p{
        margin: 0;
        padding: 0;
        line-height: normal;
    }
    .cell-render-shopping-ad-img{
        margin: 5px 5px 0 0 ;
        width: 120px;
        height: 90px;
        padding: 5px 10px;
        border: 1px solid #e2e2e2;
        background-color: #f5f5f5;
        border-radius: 6px;
        text-align: center;
        img{
            background-size: contain;
            max-width: 105px;
            max-height: 75px;
        }
    }
    .cell-render-shopping-ad-title{
        margin: 4px 0 0 0;
        color: #1a0dab;
        font-weight: 600;
        font-size: 14px;
    }
    .cell-render-shopping-ad-price{
        color: $dark;
        margin: 6px 0 0 0;
        font-size: 13px;
        font-weight: 600;

    }
    .cell-render-shopping-ad-rate{
        margin: 4px 0 0 0;
        color: $googleResultTypeAdwords;
        font-size: 13px;
    }
    .cell-render-shopping-ad-website{
        font-weight: 600;
        margin: 6px 0 0 0;
        font-size: 14px;
    }
    .cell-render-shopping-ad-url{
        margin: 6px 0 0 0;
        font-size: 14px;
    }

    .cell-render-shopping-ad-link{
        margin: 6px 0 0 0;
        color: #1a0dab;
        font-weight: 600;
        font-size: 12px;
    }
}

</style>
