<template>

    <div class="custom-cellrenderer-progressbar">
        <div>
            <div :style="`width: ${params.value.value ? params.value.value : 0 }%; background-color: ${params.value.color}`"/>
        </div>
        <p class="ml-2 mt-0">{{params.value.value}}</p>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">

</style>
