<template>
    <div
        class="cellrenderer-linking-popularity-detail"
        :style="`color: ${params.value.color}; border-color:${params.value.color}; background-color:${params.value.color}10`"
    >
        {{ params.value.value }}
    </div>

</template>

<script>
export default {

}
</script>

<style lang="scss">

</style>
