<template>
    <div class="cell-renderer-text-with-icon">
        <v-icon
            :size="params.data.iconSize"
            :color="params.data.iconColor"
        >
            mdi-{{ params.data.iconName }}
        </v-icon>
        <span>{{ params.value }}</span>
    </div>
</template>

<script>
export default {
    name: "CellRendererTextWithIcon",
}
</script>

<style lang="scss">
.cell-renderer-text-with-icon{
    display: flex;
    align-items: center;
    span {
        display: inline-block;
        margin: 0 0 0 12px;
    }
}
</style>
